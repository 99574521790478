import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/notifications",
    name: "NotificationsIndex",
    component: () =>
      import(
        /* webpackChunkName: "NotificationsIndex" */ "../views/Notifications/Index.vue"
      ),
  },
  {
    path: "/",
    redirect: store.getters.authIsLogged ? "/store" : "/login",
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Auth/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.authIsLogged) {
        next({ path: "/store" });
      } else {
        next();
      }
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/Auth/ForgotPassword.vue"
      ),
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ "../views/Auth/PasswordReset.vue"
      ),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/Auth/Register.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters.storefrontStorefront.public_registration) {
        next({ path: "/login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/businesses/:id",
    name: "BusinessesShow",
    component: () =>
      import(
        /* webpackChunkName: "BusinessesShow" */ "../views/Businesses/Show.vue"
      ),
  },
  {
    path: "/businesses",
    name: "BusinessesIndex",
    component: () =>
      import(
        /* webpackChunkName: "BusinessesIndex" */ "../views/Businesses/Index.vue"
      ),
  },
  {
    path: "/constants",
    name: "ConstantsIndex",
    component: () =>
      import(
        /* webpackChunkName: "ConstantsIndex" */ "../views/Constants/Index.vue"
      ),
  },
  {
    path: "/contact",
    name: "ContactIndex",
    component: () =>
      import(
        /* webpackChunkName: "ContactIndex" */ "../views/Contact/Index.vue"
      ),
  },
  {
    path: "/products/incomplete",
    name: "IncompleteProductsIndex",
    component: () =>
      import(
        /* webpackChunkName: "IncompleteProductsIndex" */ "../views/Products/IncompleteProducts.vue"
      ),
  },
  {
    path: "/products/restrictions",
    name: "RestrictionsIndex",
    component: () =>
      import(
        /* webpackChunkName: "RestrictionsIndex" */ "../views/Products/Restrictions.vue"
      ),
  },
  {
    path: "/product-sync",
    name: "ProductSyncIndex",
    component: () =>
      import(
        /* webpackChunkName: "ProductSyncIndex" */ "../views/ProductSync/Index.vue"
      ),
  },
  {
    path: "/providers/:id",
    name: "ProvidersShow",
    component: () =>
      import(
        /* webpackChunkName: "ProvidersShow" */ "../views/Providers/Show.vue"
      ),
  },
  {
    path: "/providers",
    name: "ProvidersIndex",
    component: () =>
      import(
        /* webpackChunkName: "ProvidersIndex" */ "../views/Providers/Index.vue"
      ),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () =>
      import(/* webpackChunkName: "Checkout" */ "../views/Quotes/Checkout.vue"),
  },
  {
    path: "/redeem/:id",
    name: "ShowRedeem",
    component: () =>
      import(
        /* webpackChunkName: "ShowRedeem" */ "../views/Quotes/ShowRedeem.vue"
      ),
  },
  {
    path: "/profile",
    name: "ProfileIndex",
    component: () =>
      import(
        /* webpackChunkName: "ProfileIndex" */ "../views/Profile/Index.vue"
      ),
  },
  {
    path: "/cart",
    name: "Cart",
    component: () =>
      import(/* webpackChunkName: "QuotesIndex" */ "../views/Quotes/Index.vue"),
  },
  {
    path: "/quotes/review",
    name: "QuotesReview",
    component: () =>
      import(
        /* webpackChunkName: "QuotesReview" */ "../views/Quotes/Review.vue"
      ),
  },
  {
    path: "/quotes/review/:id",
    name: "QuotesReviewShow",
    component: () =>
      import(
        /* webpackChunkName: "QuotesReviewShow" */ "../views/Quotes/Show.vue"
      ),
  },
  {
    path: "/quotes/resolution/:id",
    name: "QuotesResolution",
    component: () =>
      import(
        /* webpackChunkName: "QuotesResolution" */ "../views/Quotes/Resolution.vue"
      ),
  },
  // {
  //   path: '/reports',
  //   name: 'ReportsIndex',
  //   component: () => import (/* webpackChunkName: "ReportsIndex" */ '../views/Reports/Index.vue')
  // },
  {
    path: "/sale-teams",
    name: "SaleTeamsIndex",
    component: () =>
      import(
        /* webpackChunkName: "SaleTeamsIndex" */ "../views/SaleTeams/Index.vue"
      ),
  },
  {
    path: "/sale-teams/:id",
    name: "SaleTeamsShow",
    component: () =>
      import(
        /* webpackChunkName: "SaleTeamsShow" */ "../views/SaleTeams/Show.vue"
      ),
  },
  {
    path: "/store",
    name: "StoreIndex",
    component: () =>
      import(/* webpackChunkName: "StoreIndex" */ "../views/Store/Index.vue"),
  },
  {
    path: "/store/search",
    name: "StoreSearch",
    component: () =>
      import(/* webpackChunkName: "StoreSearch" */ "../views/Store/Search.vue"),
  },
  {
    path: "/store/:id",
    name: "StoreShow",
    component: () =>
      import(/* webpackChunkName: "StoreShow" */ "../views/Store/Show.vue"),
  },
  {
    path: "/points",
    name: "PointsIndex",
    component: () =>
      import(/* webpackChunkName: "PointsIndex" */ "../views/Points/Index.vue"),
  },
  {
    path: "/users",
    name: "UsersIndex",
    component: () =>
      import(/* webpackChunkName: "UsersIndex" */ "../views/Users/Index.vue"),
  },
  {
    path: "/users/:id",
    name: "UsersShow",
    component: () =>
      import(/* webpackChunkName: "UsersShow" */ "../views/Users/Show.vue"),
  },
  {
    path: "/:campaign",
    redirect: (to) => ({
      name: "StoreSearch",
      query: {
        campaign: to.params.campaign,
      },
    }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    !store.getters.authIsLogged &&
    to.path !== "/login" &&
    to.path !== "/register" &&
    to.path !== "/forgot-password" &&
    to.path !== "/password-reset"
  ) {
    next({
      path: "/login",
      query: {
        callbackUrl:
          to.path +
          "?" +
          Object.keys(to.params).reduce(
            (accum, param) => accum + "&" + param + "=" + to.params[param],
            ""
          ),
      },
    });
  } else {
    next();
  }
});

export default router;
